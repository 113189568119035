import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { ProjectProps, SourcesType } from "@/Types/projects";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import GenerateLoading from "@/Components/GenerateLoading";
import SourcesList from "@/Components/Projects/SourcesList";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import Card from "./Partials/Card";
import { useGenerate } from "@/Context/hooks/useGenerate";

import useRolesBadge from "@/Hooks/useRolesBadge";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { progressPortion } from "@/Utils/dispatcher";
import useUser from "@/Hooks/react-query/useUser";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import SliderContainer from "@/Components/Projects/SliderContainer";
import SelectedCard from "@/Components/SelectedCard";
import EditButton from "@/Components/Projects/EditButton";
import toast from "react-hot-toast";
import { RouteOption } from "@/Context/reducer/projectReducer";
import { SectionList } from "@/Types/tabs";
import RegenerateInfoModal from "@/Components/Modals/RegenerateInfoModal";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import { useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import {
  IdeaPersonalizationContent,
  IdeaPersonalizationList,
} from "@/Types/idea_personalization";
import { useUpdateIdeaPersonalization } from "@/Hooks/react-query/IdeaPersonalization/useUpdate";
import useIdeaPersonalization from "@/Hooks/react-query/IdeaPersonalization/useGetList";

import SectionInformation from "@/Components/Projects/SectionInformation";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [editedData, setEditedData] = useState<IdeaPersonalizationList[]>([]);
  const [parsedData, setParsedData] = useState<IdeaPersonalizationList[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [selectedOpportunityIndex, setSelectedOpportunityIndex] = useState<
    number[] | undefined
  >();
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const {
    isIdeaPersonalization,
    isIdeaExpansion,
    isUpdateState: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    isPending: isePendingRefetch,
  } = useIdeaPersonalization(project.slug);
  const { mutate, isPending: isSubmitting } = useUpdateIdeaPersonalization();
  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: "Generate Idea Expansion",
    isActive: true,
    isGenerate: true,
    isInactive: true,
  });

  const isEditor = roles.includes("Owner") || roles.includes("Creative");
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleCancelEdit = async () => {
    toggleIsEditing();
    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const { handleGenerateIdeaPersonalization, handleGenerateIdeaExpansion } =
    useGenerate(dispatch);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const handleSaveEditedData = (
    data: Omit<IdeaPersonalizationContent, "idea_personalization_number">,
    index: number,
    id: number,
    archetypeId: number,
  ) => {
    mutate(
      {
        payload: data,
        projectSlug: project.slug,
        id,
        archetypeId,
      },
      {
        onSuccess: () => {
          refreshData();
          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
        onError: (error) => {
          toast.error(error.message);
          refreshData();
        },
      },
    );
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ["ideaExpansion", project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    handleGenerateIdeaExpansion()(project.slug);

    navigate(`/${project.slug}/idea-expansion`);
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
  };

  const hasSelectedData = (parseData: IdeaPersonalizationList[]) => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedIdea = parseData
      .map((section, index) => {
        const hasSelectedIdea = section.idea_personalizations?.some(
          (idea_personalization) => idea_personalization.is_selected,
        );
        return hasSelectedIdea ? -1 : index;
      })
      .filter((index) => index !== -1);
    if (indicesWithoutSelectedIdea.length) {
      setErrorTabIndex(indicesWithoutSelectedIdea);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedIdea
        .map((index) => `Archetype ${index + 1}`)
        .join(", ");
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select Idea Personalization for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRegenerateButton = async (prompt: string) => {
    await handleGenerateIdeaPersonalization()(project.slug, prompt);
    setParsedData([]);
    setErrorTabIndex([]);
    setIsGenerated(true);
    dispatch(saveSubInformation(``));
  };

  const handleRegenerateNextProcess = async () => {
    setIsReGenerating(true);
    dispatch(saveSubInformation(""));
    handleGenerateIdeaExpansion()(project.slug, null);
    setIsReGenerating(false);
    navigate(`/${project.slug}/idea-expansion`);
  };

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (selectedIndex < parsedData.length - 1) {
      const nextIndex = selectedIndex + 1;
      setSelectedIndex(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        "scrollIntoView" in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1;
      setSelectedIndex(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        "scrollIntoView" in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const refreshData = () => {
    isAdding && toggleIsAdding();
    isEditing && toggleIsEditing();

    setTimeout(() => {
      refetch();
    }, 700);
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/idea-alignment`);
        },
      }),
    );
    dispatch(saveSubInformation(""));
  }, []);

  useEffect(() => {
    setGoToIndex(-1);
  }, [selectedIndex]);

  const countSelectedData = (data: IdeaPersonalizationList[]) => {
    let totalKeyInsights = 0;
    let selectedKeyInsights = 0;

    data.forEach((idea) => {
      if (
        idea.idea_personalizations &&
        Array.isArray(idea.idea_personalizations)
      ) {
        idea.idea_personalizations?.forEach((ideaPersonalization) => {
          totalKeyInsights++;
          if (ideaPersonalization.is_selected) {
            selectedKeyInsights++;
          }
        });
      }
    });

    const isArchetypeAlreadySelected = data.every((item) =>
      item.idea_personalizations.some(
        (personalization) => personalization.is_selected,
      ),
    );

    setRouteOptionValue((oldValue) => ({
      ...oldValue,
      isInactive:
        !isIdeaPersonalization.status || isIdeaPersonalization.isQuerying
          ? true
          : isArchetypeAlreadySelected
          ? false
          : true,
      onClick: () => {
        if (!isIdeaPersonalization.status || isIdeaPersonalization.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, idea personalization is still generating`,
            ),
          );
          return;
        }

        if (isEditor) {
          if (isIdeaExpansion.isQuerying) {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, idea expansion is still generating`,
              ),
            );

            setTimeout(() => {
              dispatch(saveSubInformation(""));
            }, 3000);
            return;
          }

          const hasError = hasSelectedData(parsedData);

          if (!hasError) {
            if (isIdeaExpansion.isRegenerated) {
              setShowRegenerateModal(true);
              return;
            }

            if (routeOptionValue.isGenerate) {
              handleRefetchSelectedData();
              return;
            }

            navigate(`/${project.slug}/idea-expansion`);
          }
        } else {
          if (!isIdeaExpansion.status) {
            setShowViewInfoModal(true);
            return;
          }
          navigate(`/${project.slug}/idea-expansion`);
        }
      },
    }));

    if (selectedKeyInsights === 0) {
      dispatch(
        saveInformation(`0 Idea Personalization in 0 Archetype is selected`),
      );
      return;
    }

    dispatch(
      saveInformation(
        `${selectedKeyInsights} from ${totalKeyInsights} Idea Personalization is selected`,
      ),
    );
  };

  useEffect(() => {
    if (data?.data) {
      const { lists, sources, total_regenerate, is_querying } = data.data;

      if (is_querying) {
        if (lists?.length === 0) {
          setEditedData([]);
          setParsedData([]);
          setSources([]);
          setTotalRegenerate(0);
        }

        setIsGenerated(true);

        setRouteOptionValue((oldValue) => ({
          ...oldValue,
          onClick: () => {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, idea personalization is still generating`,
              ),
            );
          },
        }));

        return;
      }

      setEditedData(lists ?? []);
      setParsedData(lists ?? []);
      setTotalRegenerate(total_regenerate ?? 0);
      setSources(sources ?? []);
      setIsGenerated(false);

      dispatch(saveSubInformation(""));
    }
  }, [data, isIdeaPersonalization.isQuerying]);

  useEffect(() => {
    if (!isIdeaPersonalization.status) {
      dispatch(saveInformation(`0 from 0 idea personalization selected`));
    }

    if (isIdeaPersonalization.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [isIdeaPersonalization, parsedData, isePendingRefetch, isPendingHistory]);

  useEffect(() => {
    if (
      !isGenerated &&
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.idea_personalizations
    ) {
      if (
        !isGenerated &&
        !isPendingHistory &&
        !isIdeaPersonalization.isQuerying
      ) {
        setIsGenerated(true);
        setParsedData([]);
        setEditedData([]);
        dispatch(saveSubInformation(""));
        handleGenerateIdeaPersonalization()(project.slug);
      }
    }
  }, [
    isGenerated,
    differentArchetypes,
    isIdeaPersonalization,
    isPendingHistory,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    if (!parsedData.length) return;

    const activeArchetype = parsedData[selectedIndex];
    const selectedDataWithIndex = activeArchetype?.idea_personalizations
      ?.map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected);

    if (selectedDataWithIndex.length) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      hasSelectedData(parsedData);
      setSelectedOpportunityIndex(indices);
    }

    countSelectedData(parsedData);
  }, [
    selectedIndex,
    parsedData,
    isIdeaPersonalization,
    isIdeaExpansion,
    isIdeaPersonalization.isQuerying,
    routeOptionValue.isGenerate,
    differentArchetypes?.is_different_archetype.idea_expansions,
  ]);

  useEffect(() => {
    if (!isEditor) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: "Next to idea Expansion",
        isGenerate: false,
      }));
      return;
    }

    if (
      isIdeaExpansion.status &&
      !isIdeaExpansion.isRegenerated &&
      !differentArchetypes?.is_different_archetype.idea_expansions
    ) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: "Next to idea Expansion",
        isGenerate: false,
      }));
    }
    if (
      !isIdeaExpansion.status &&
      !isIdeaExpansion.isRegenerated &&
      !differentArchetypes?.is_different_archetype.idea_expansions
    ) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: "Generate idea Expansion",
        isGenerate: true,
      }));
    }
    if (
      isIdeaExpansion.status &&
      (isIdeaExpansion.isRegenerated ||
        differentArchetypes?.is_different_archetype.idea_expansions)
    ) {
      setRouteOptionValue((oldValue) => ({
        ...oldValue,
        label: "Regenerate idea Expansion",
        isGenerate: true,
      }));
    }
  }, [isIdeaExpansion, isEditor, isGenerated]);

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
  }, [routeOptionValue]);

  useEffect(() => {
    !isIdeaPersonalization.isQuerying && !isPendingHistory && refetch();
  }, [isGenerated, isIdeaPersonalization.isQuerying, isPendingHistory]);

  const sectionList: SectionList = {
    title: "Idea Personalization",
    value: "personalization",
    section: "idea",
  };

  return (
    <Fragment>
      <title title='Idea Personalization ' />
      {isGenerated ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number(
              (state.ideaPersonalization.progress / 2).toFixed(0),
            ),
            isQuerying: isIdeaPersonalization.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
        />
      ) : (
        <div className='w-full h-full'>
          <div className='flex items-center justify-between sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col w-2/3'>
              <div className='inline-flex items-center gap-12 mb-8'>
                <h1 className='text-25 font-bold leading-none'>
                  Idea Personalization
                </h1>

                <SectionInformation
                  title='What did Ainstein do with Idea Personalization?'
                  description='Ainstein will tailor ideas according to the chosen archetype and the alignment of previous idea, ensuring they fit seamlessly with your strategic framework.'
                />
              </div>
              <span className='text-15 font-normal text-grey-redx'>
                Please{" "}
                <span className='font-bold text-black-redx'>
                  select one idea for each archetype
                </span>
                , edit, or add by clicking 'Add Idea Manually'.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                limit={totalRegenerate}
                isGenerateMore={true}
                maxLimit={maxGenerateLimit}
              />
            )}
          </div>

          <TabGroup
            className='pb-80'
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}>
            <div className='relative flex items-center'>
              {!isLoading &&
                !isIdeaPersonalization.isQuerying &&
                !isRefetching &&
                parsedData.length > 3 && (
                  <button
                    onClick={handlePrev}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={selectedIndex === 0}>
                    <Icon
                      icon='mingcute:left-line'
                      className='text-25'
                    />
                  </button>
                )}
              <TabList
                className={
                  "flex w-full overflow-x-auto scrollbar-hide border-b-1 border-grey-200"
                }>
                {!isLoading &&
                !isIdeaPersonalization.isQuerying &&
                !isRefetching
                  ? parsedData.map((section, index) => (
                      <Tab
                        disabled={isEditing || isAdding}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className='text-15 px-25 pb-10 text-black-redx min-w-5/24 w-full md:min-w-7/12 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                        key={index}>
                        <div className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none inline-flex gap-8 items-center justify-center'>
                          <div className='flex flex-col gap-8'>
                            {`Archetype ${index + 1}`}
                            <div className='px-8 py-4 bg-soft-grey-redx rounded-8 font-semibold text-black-redx'>
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex && errorTabIndex.includes(index) && (
                            <Icon
                              icon='mingcute:information-line'
                              className='text-25 text-error-redx'
                            />
                          )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none flex flex-col gap-8 items-center justify-center text-gray-600 hover:text-indigo-600'>
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isIdeaPersonalization.isQuerying &&
                !isRefetching &&
                parsedData.length > 3 && (
                  <button
                    onClick={handleNext}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={selectedIndex === parsedData.length - 1}>
                    <Icon
                      icon='mingcute:right-line'
                      className='text-25'
                    />
                  </button>
                )}
            </div>
            {!isLoading &&
            !isIdeaPersonalization.isQuerying &&
            !isRefetching ? (
              <TabPanels className={"py-24"}>
                {parsedData.map((section, index) => (
                  <TabPanel key={index}>
                    <div
                      className='pt-5'
                      key={index}>
                      <SelectedCard
                        title={`Archetype Detail - ${section.archetype_content.archetype_name}`}
                        data={[
                          {
                            title: "Archetype Name",
                            content: section.archetype_content.archetype_name,
                          },
                          {
                            title: "Demographic",
                            content: section.archetype_content.demographics,
                          },
                          {
                            title: "Occupation",
                            content: section.archetype_content.occupation,
                          },
                          {
                            title: "Lifestyle",
                            content: section.archetype_content.lifestyle,
                          },
                          {
                            title: "Behavior",
                            content: section.archetype_content.behavior,
                          },
                          {
                            title: "Need & Challenges",
                            content:
                              section.archetype_content.needs_and_challenges,
                          },
                          {
                            title: "Potential Buying Motives",
                            content:
                              section.archetype_content
                                .potential_buying_motives,
                          },
                          {
                            title: "Current Trends",
                            content: section.archetype_content.current_trends,
                          },
                          {
                            title: "Source of Information",
                            content:
                              section.archetype_content.source_of_information,
                          },
                          {
                            title: "Online Search Behavior",
                            content:
                              section.archetype_content.online_search_behavior,
                          },
                          {
                            title: "Purchase Frequency",
                            content:
                              section.archetype_content.purchase_frequency,
                          },
                          {
                            title: "Preferred Sales Channels",
                            content:
                              section.archetype_content
                                .preferred_sales_channels,
                          },
                        ]}
                      />
                      <div className='mt-20' />
                      <SliderContainer
                        isEditing={isEditing}
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        length={section.idea_personalizations?.length}
                        selectedIndexes={selectedOpportunityIndex}
                        onActiveIndex={(index) =>
                          isAdding ? setActiveIndex(0) : setActiveIndex(index)
                        }>
                        {section.idea_personalizations?.map((item, index) => (
                          <Card
                            archetypeId={section.id}
                            key={index}
                            index={index}
                            isEditing={isEditing}
                            isFetching={isRefetching}
                            item={item}
                            totalItem={
                              section.idea_personalizations?.length ?? 0
                            }
                            onSaveEditedData={handleSaveEditedData}
                            isCanEdit={isEditor}
                            ref={(el) => (cardRefs.current[index] = el)}
                            onSuccessSelected={(index) => {
                              refetch().then((data) => {
                                if (data) {
                                  setParsedData(data!.data!.data!.lists!);
                                  setEditedData(data!.data!.data!.lists!);

                                  hasSelectedData(data!.data!.data!.lists!);
                                  countSelectedData(data!.data!.data!.lists!);
                                  setGoToIndex(index - 1);
                                }
                              });
                              // todo :  recheck req payload to show alert if there is any changed setShowAlertGenerateModal(true)
                            }}
                          />
                        ))}
                        {isAdding && (
                          <Card
                            archetypeId={section.id}
                            index={section.idea_personalizations?.length}
                            isAdding={isAdding}
                            totalItem={
                              section.idea_personalizations?.length + 1
                            }
                            onSaveEditedData={handleSaveEditedData}
                            isCanEdit={isEditor}
                            ref={(el) => (cardRefs.current[0] = el)}
                          />
                        )}
                      </SliderContainer>
                      <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                        {isEditor &&
                          (!isAdding && !isEditing ? (
                            <button
                              className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                              onClick={toggleIsAdding}>
                              Add Idea Personalization manually
                              <Icon
                                icon='lucide:plus'
                                className='w-20 h-20'
                              />
                            </button>
                          ) : (
                            <div></div>
                          ))}
                        <div className='flex justify-end items-center gap-x-15'>
                          {isEditor &&
                            (isEditing || isAdding ? (
                              <Fragment>
                                <button
                                  disabled={isSubmitting}
                                  className={`inline-flex items-center gap-x-4 cursor-pointer ${
                                    isSubmitting
                                      ? "text-gray-redx"
                                      : "text-red-500"
                                  }`}
                                  onClick={
                                    isAdding ? toggleIsAdding : handleCancelEdit
                                  }>
                                  <span className='text-15 font-semibold'>
                                    Cancel
                                  </span>
                                  <Icon
                                    icon='lucide:x'
                                    className='w-20 h-20'
                                  />
                                </button>
                                <button
                                  disabled={isSubmitting}
                                  className={`inline-flex items-center gap-x-4 cursor-pointer ${
                                    isSubmitting
                                      ? "text-gray-redx"
                                      : "text-blue-redx"
                                  }`}
                                  onClick={handleSubmit}>
                                  <span className='text-15 font-semibold'>
                                    Save
                                  </span>
                                  <Icon
                                    icon='lucide:save'
                                    className='w-20 h-20'
                                  />
                                </button>
                              </Fragment>
                            ) : (
                              <EditButton toggleEditing={toggleIsEditing} />
                            ))}
                        </div>
                      </div>
                      <div className='flex w-full pb-60'>
                        {sources && <SourcesList sources={sources} />}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <div className='block'>
                <div className='w-full h-55 animate-pulse bg-soft-purple-redx rounded-8' />
                <div className='mt-20' />
                <SliderContainer
                  isEditing={isEditing}
                  isAdding={isAdding}
                  length={1}
                  goToIndex={goToIndex}
                  onActiveIndex={undefined}>
                  <Card
                    totalItem={0}
                    isFetching={true}
                    index={-1}
                    isEditing={isAdding}
                    ref={(el) => (cardRefs.current[-1] = el)}
                  />
                </SliderContainer>
                <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                  {isEditor &&
                    (!isAdding && !isEditing ? (
                      <button
                        className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                        onClick={undefined}>
                        Add Idea Personalization Manually
                        <Icon
                          icon='lucide:plus'
                          className='w-20 h-20'
                        />
                      </button>
                    ) : (
                      <div></div>
                    ))}
                  <div className='flex justify-end items-center gap-x-15'>
                    {isEditor &&
                      (isEditing || isAdding ? (
                        <Fragment>
                          <button
                            className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>
                              Cancel
                            </span>
                            <Icon
                              icon='lucide:x'
                              className='w-20 h-20'
                            />
                          </button>
                          <button
                            disabled={false}
                            className={`inline-flex items-center gap-x-4 ${
                              false ? "text-gray-600" : "text-blue-redx"
                            } cursor-pointer`}
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>Save</span>
                            <Icon
                              icon='lucide:save'
                              className='w-20 h-20'
                            />
                          </button>
                        </Fragment>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className='flex w-full pb-60'>
                  <div className='inline-flex gap-10'>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerateNextProcess}
        onClose={() => setShowRegenerateModal(false)}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </Fragment>
  );
};

export default Index;
